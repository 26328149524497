import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    status: Object
  }

  static targets = [
    'noGiftLabel',
    'addingGiftLabel',
    'giftLabel',
    'giftMessage',
    'giftForm',
    'button',
    'textArea',
    'circle'
  ]

  connect () {
    this.message = document.querySelector('#gift_message').value
    this.formShow = false
    this.updateCircle(this.message.length)
    this.removeShake = this.removeShake.bind(this)
  }

  startSave (e) {
    this.message = document.querySelector('#gift_message').value
    this.giftMessageTarget.innerHTML = this.message
    this.toggleForm(e)
  }

  changeMessage (e) {
    const size = e.target.value.length
    const maxSize = e.target.getAttribute('maxlength')
    if (size === parseInt(maxSize)) {
      this.textAreaTarget.classList.add('animate__animated')
      this.textAreaTarget.classList.add('animate__headShake')
      setTimeout(this.removeShake, 2000)
    }
    this.updateCircle(size)
  }

  removeShake () {
    this.textAreaTarget.classList.remove('animate__animated')
    this.textAreaTarget.classList.remove('animate__headShake')
  }

  updateCircle (size) {
    const maxSize =
      document.querySelector('#gift_message').getAttribute('maxlength') / 2
    if (size <= maxSize) {
      const newDeg = 90 + 1.8 * (size / (maxSize / 100))
      this.circleTarget.style = `background: linear-gradient(${newDeg}deg, transparent 50%, #c4c0bc 50%), linear-gradient(90deg, #c4c0bc 50%, transparent 50%); background-color: #0957c3;`
    } else {
      const newDeg = -180 + 2.7 * (size / 2 / (maxSize / 100))
      this.circleTarget.style = `background: linear-gradient(270deg, #0957C3 50%, transparent 50%), linear-gradient(${newDeg}deg, #0957C3 50%, #c4c0bc 50%); background-color: #0957c3;`
    }
  }

  toggleForm (e) {
    e.preventDefault()

    this.formShow = !this.formShow
    this.giftMessageTarget.classList.toggle('cart-show-gift__message--hide')
    this.giftFormTarget.classList.toggle('cart-show-gift__form--hide')
    if (this.message !== '' && this.formShow === false) {
      this.buttonTarget.innerHTML = this.statusValue.notEmpty
      this.buttonTarget.classList.remove('cart-button--edit')
      return
    }
    if (this.message === '' && this.formShow === false) {
      this.buttonTarget.innerHTML = this.statusValue.empty
      this.buttonTarget.classList.remove('cart-button--edit')
      return
    }
    this.buttonTarget.innerHTML = this.statusValue.edit
    this.buttonTarget.classList.add('cart-button--edit')
  }
}
