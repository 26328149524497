import { Controller } from '@hotwired/stimulus'
import submitJsonForm from '../utils/submitJsonForm'

// This simple snippet should help building the main button logic:
//
//   {% for variant in product.variants %}
//     {% if variant.in_stock? %}
//       <div data-controller="add-to-cart" data-add-to-cart-loading-class-value="loading">
//         <a href="{{ "/cart" | url }}" class="{% unless variant.in_cart? %} hidden{% endunless %}" data-add-to-cart-target="goto">
//           Already in cart
//         </a>
//         <div class="{% if variant.in_cart? %}hidden{% endif %}">
//           {% form "add_to_cart" %}
//             <input type="hidden" name="product_id" value="{{ product.id }}" />
//             <input type="hidden" name="product_variant_color_name" value="{{ variant.color_name }}" />
//             {% if product.sad_price %}
//               <label data-action="click->add-to-cart#toggleSad">
//                 <input name="sad_vat" type="hidden" value="0">
//                 <input data-add-to-cart-target="checkbox" type="checkbox" value="1" name="sad_vat" id="sad_vat">
//                 I Have Sad
//               </label>
//             {% endif %}
//             <button data-add-to-cart-target="button">
//               Add to basket,
//               <span data-add-to-cart-target="fullPrice">{{ product.price }}</span>
//               <span class="hidden" data-add-to-cart-target="sadPrice">{{ product.sad_price }}</span>
//             </button>
//           {% endform %}
//         </div>
//       </div>
//     {% else %}
//       Out of stock
//     {% endif %}
//   {% endfor %}
//
export default class extends Controller {
  static values = {
    loadingClass: String
  }

  static targets = [
    'button',
    'goto',
    'form',
    'sadPrice',
    'fullPrice',
    'checkbox'
  ]

  async onSubmit (e) {
    e.preventDefault()
    this.requestStart()

    const response = await submitJsonForm(this.formTarget)

    this.buttonTarget.classList.remove(this.loadingClass())
    this.formTarget.classList.add('hidden')
    this.gotoTarget.classList.remove('hidden')

    const event = new CustomEvent('cart-quantity-changed', {
      detail: { totalQuantity: response.cart.totalQuantity }
    })
    window.dispatchEvent(event)
  }

  requestStart () {
    this.buttonTarget.classList.add(this.loadingClass())
  }

  toggleSad () {
    this.checkboxTarget.checked = !this.checkboxTarget.checked

    if (this.checkboxTarget.checked) {
      this.fullPriceTarget.classList.add('hidden')
      this.sadPriceTarget.classList.remove('hidden')
    } else {
      this.fullPriceTarget.classList.remove('hidden')
      this.sadPriceTarget.classList.add('hidden')
    }
  }

  loadingClass () {
    return this.hasLoadingClassValue ? this.loadingClassValue : 'add-to-cart__button--loading'
  }
}
