import { Controller } from '@hotwired/stimulus'
import { loadScript } from '@paypal/paypal-js'
import { getMeta, showErrorBox } from '../utils/dom'

export default class extends Controller {
  static targets = [
    'button',
    'paymentMethod'
  ]

  static values = {
    clientId: String,
    currency: String
  }

  static confirmUrl

  initialize () {
    this.initPaypalButton = this.initPaypalButton.bind(this)
    this.createPaypalOrder = this.createPaypalOrder.bind(this)
    this.onPaypalApprove = this.onPaypalApprove.bind(this)
  }

  async connect () {
    await loadScript({
      clientId: this.clientIdValue,
      currency: this.currencyValue.toUpperCase(),
      intent: 'capture',
      disableFunding: 'credit,card,sofort,venmo,sepa,p24,mybank,mercadopago,ideal,giropay,eps,blik,bancontact'
    }).then(this.initPaypalButton)
  }

  disconnect () {
    this.removePaypal()
  }

  initPaypalButton (paypal) {
    if (this.hasButtonTarget) {
      paypal.Buttons({
        style: {
          layout: 'vertical',
          color: 'white',
          shape: 'pill',
          label: 'paypal',
          height: 55
        },
        createOrder: this.createPaypalOrder,
        onApprove: this.onPaypalApprove,
        onError: this.onPaypalError
      }).render(this.buttonTarget)
    }
  }

  async createPaypalOrder () {
    this.paymentMethodTarget.value = 'paypal'

    const { form } = this.paymentMethodTarget
    const formData = new FormData(form)
    const response = await fetch(form.action, {
      method: form.method,
      body: formData
    })
    const responseData = await response.json()
    this.confirmUrl = responseData.confirm_url

    return responseData.token
  }

  async onPaypalApprove (data) {
    const csrfToken = getMeta('csrf-token')
    const response = await fetch(this.confirmUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ paypal_order: data })
    })

    try {
      const responseData = await response.json()
      if (responseData.redirect_url) {
        window.location = responseData.redirect_url
      } else {
        showErrorBox(
          'paypal-message-error',
          'Payment error',
          'Chat with us'
        )
      }
    } catch (e) {
      showErrorBox(
        'paypal-message-error',
        'Internal server error',
        'Chat with us'
      )
    }
  }

  onPaypalError (data) {
    showErrorBox(
      'paypal-message-error',
      'Internal server error',
      data
    )
  }

  removePaypal () {
    Object.keys(window).forEach((key) => {
      if (/paypal|zoid|post_robot/.test(key)) {
        delete window[key]
      }
    })
  }
}
