import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['title', 'text']

  static values = {
    trigger: Boolean
  }

  triggerValueChanged () {
    if (this.triggerValue) {
      this.show()
      setTimeout(
        () => {
          this.close()
        },
        5000,
        this
      )
    }
  }

  close () {
    this.triggerValue = false
    this.element.classList.add('hidden')
  }

  show () {
    this.element.classList.remove('hidden')
  }
}
